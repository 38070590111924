<template>
  <div class="patient">
    <radio-button
      class="page-top"
      :type.sync="currentTab"
      :btnList="[
        { label: '基础信息', value: '1' },
        { label: '电子病历', value: '2' },
      ]"
      @change="tabChange"
    ></radio-button>

    <template v-if="currentTab === '1'">
      <c-detail
        :data="detailData"
        :columns="[
          { label: '患者姓名', prop: 'cardName' },
          { label: '患者卡号', prop: 'cardId' },
          { label: '患者年龄', prop: 'age' },
          { label: '出生日期', prop: 'birthday' },
          { label: '性别', prop: 'sexName' },
          { label: '手机号', prop: 'phone' },
          { label: '证件号', prop: 'cardNo' },
          { label: '接诊类型', prop: 'visitType', value: detailData.visitType === '1' ? '初诊' : '复诊' },
          { label: '婚姻状况', prop: 'marriage', type: 'select', list: [{ text: '未知', value: '' }, { text: '未婚', value: '1' }, { text: '已婚', value: '2' }] },
          { label: '学历', prop: 'education' },
          { label: '职业', prop: 'profession' },
          { label: '地址', prop: 'addressDetail' },
          { label: '备注', prop: 'remark' },
        ]"
      ></c-detail>
    </template>

    <template v-else>
      <c-detail
        v-for="item in list"
        :key="item.id"
        :data="item"
        :title="item.updateTime"
        :columns="[
          { label: '门诊编号', prop: 'businessId' },
          { label: '接诊医生', prop: 'doctorName' },
          { label: '科室', prop: 'deptName' },
          { label: '诊断', prop: 'diagnosis' },
        ]"
        @click.native="showDetail(item)"
      ></c-detail>
    </template>

    <c-dialog
      :dialog="dialog"
    >
      <div class="tac">
        <van-tag type="primary" plain>{{ dialog.data.visitType === '1' ? '初诊' : '复诊' }}</van-tag>
      </div>
      <div class="common-title">体格</div>
      <c-detail
        :data="dialog.data"
        :columns="[
          { label: '体温', prop: 'temperature', className: 'w50' },
          { label: '呼吸', prop: 'breathing', className: 'w50' },
          { label: '脉搏', prop: 'pulse', className: 'w50' },
          { label: '血压', prop: 'bloodLow', value: `${dialog.data.bloodLow}~${dialog.data.bloodHigh}`, className: 'w50' },
          { label: '血糖', prop: 'bloodSugar', className: 'w50' },
          { label: '血脂', prop: 'bloodFat', className: 'w50' },
          { label: '身高', prop: 'height', className: 'w50' },
          { label: '体重', prop: 'weight', className: 'w50' },
        ]"
      ></c-detail>
      <div class="common-title">病历信息</div>
      <c-detail
        :data="dialog.data"
        :columns="[
          { label: '发病日期', prop: 'morbidity' },
          { label: '主诉', prop: 'mainSuit' },
          { label: '现病史', prop: 'nowMedicalHistory' },
          { label: '既往史', prop: 'pastMedicalHistory' },
          { label: '过敏史', prop: 'allergicHistory' },
          { label: '个人史', prop: 'personalHistory' },
          { label: '家族史', prop: 'familyHistory' },
          { label: '辅助检查', prop: 'auxiliaryCheck' },
          { label: '医嘱', prop: 'doctorAdvice' },
          { label: '备注', prop: 'remark' },
        ]"
      ></c-detail>
    </c-dialog>
  </div>
</template>

<script>
import radioButton from '@/components/radioButton.vue';
import CDetail from '@/components/cDetail.vue';
import CDialog from '@/components/cDialog.vue';

export default {
  components: { radioButton, CDetail, CDialog },
  name: 'Patient',
  data() {
    return {
      currentTab: this.$route.query.currentTab || '1',
      businessId: this.$route.query.businessId,
      detailData: {},
      list: [],
      dialog: {
        visible: false,
        title: '',
        data: {},
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    tabChange(currentTab) {
      if (currentTab === this.$route.query.currentTab) return;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          currentTab: this.currentTab,
        },
      });
      this.getData();
    },
    getData() {
      if (this.currentTab === '1') {
        this.$get(`/api/blade-consult/doctorWork/getPatientInfo/${this.businessId}`)
          .then(({ data }) => {
            this.detailData = data || {};
          });
      } else {
        this.$get(`/api/blade-consult/doctorWork/getElectronicMedicalRecords/${this.businessId}`)
          .then(({ data }) => {
            this.list = data || [];
          });
      }
    },
    showDetail(item) {
      this.dialog = {
        visible: true,
        title: item.updateTime,
        data: {
          ...item,
        },
      };
    },
  },
};
</script>
