<template>
  <div class="detail">
    <c-card class="br6">
      <template #header>
        <div class="flex-between-center">
          <div class="flex-start-center">
            <van-image
              v-if="userInfo.cardAvatar"
              :src="userInfo.cardAvatar"
              width="40"
              height="40"
              round
            ></van-image>
            <van-image
              v-else
              class="avatar"
              :src="userInfo.sex == 1 ? require('@/assets/images/patient_m.png') : require('@/assets/images/patient_w.png')"
              width="40"
              height="40"
            ></van-image>
            <span class="ml4">{{ userInfo.cardName }}</span>
            <span class="ml4">{{ userInfo.sexName }}</span>
            <span class="ml4 mr12">{{ userInfo.age }}岁</span>
            <van-tag type="primary" plain round v-if="item.label === 1">接诊中</van-tag>
            <van-tag type="warning" plain round v-if="item.label === 2">候诊中</van-tag>
            <van-tag type="danger" plain round v-if="item.label === 3">待候诊</van-tag>
          </div>
          <div class="c999">{{ item.createTime }}</div>
        </div>
      </template>
      <div class="dark">病情描述: {{ userInfo.decription }}</div>
    </c-card>

    <!-- 按钮 -->
    <div class="fixed-btns">
      <div v-if="userInfo.diagnosisStatus !== 2" class="mb12">
        <van-button
          type="danger"
          class="w80"
          size="small"
          plain
          round
          @click="toDiagnosisPage">下诊断</van-button>
      </div>
      <div class="mb12">
        <van-button
          type="info"
          class="w80"
          size="small"
          plain
          round
          @click="toPatientPage">查看病历</van-button>
      </div>
      <div class="mb12">
        <van-button
          type="danger"
          class="w80"
          size="small"
          plain
          round
          @click="refundAction">退费</van-button>
      </div>
      <div class="mb12">
        <van-button
          type="primary"
          class="w80"
          size="small"
          plain
          round
          @click="passAction">过号</van-button>
      </div>
      <div class="mb12">
        <van-button
          type="danger"
          class="w80"
          size="small"
          plain
          round
          @click="endAction">结束问诊</van-button>
      </div>
    </div>

    <!-- 视频大窗口 -->
    <div v-if="videoStatus === 'connected' && !isLittleWindow" class="full-window" id="fullwindow">
      <div class="flex-around-center">
        <div></div>
      </div>
    </div>

    <!-- 视频小窗口 -->
    <div v-if="videoStatus === 'connected' && isLittleWindow" class="little-window">
      <div id="littlewindow" class="little-window-inner" @click="enlargeWindow">
        <div></div>
      </div>
    </div>

    <c-sticky
      :btns="[
        ...(videoStatus === 'idle' ? [
          { text: '发起视频', click: startVideo },
        ] : [
        ]),
        ...(videoStatus === 'connected' ? [
          { text: '结束视频', type: 'danger', click: endVideo },
          ...(isLittleWindow ? [] : [
            { text: '缩小窗口', type: 'primary', click: shrinkWindow },
          ]),
        ] : []),
      ]"
    ></c-sticky>

    <!-- 诊断窗口 -->
    <c-dialog
      v-if="diagnosisDialog.visible"
      :dialog="diagnosisDialog"
      class="full-screen"
    >
      <van-icon name="cross" class="close-icon" @click="closeDiagnosisDialog"></van-icon>
      <diagnosis
        type="video"
        @update="doneDiagnosis"
      ></diagnosis>
    </c-dialog>

    <!-- 病历窗口 -->
    <c-dialog
      v-if="patientDialog.visible"
      :dialog="patientDialog"
      class="full-screen"
    >
      <van-icon name="cross" class="close-icon" @click="closePatientDialog"></van-icon>
      <patient></patient>
    </c-dialog>
  </div>
</template>

<script>
// 视频
import TrtcCalling from 'trtc-calling-js';
import cCard from '@/components/cCard.vue';
import CSticky from '@/components/cSticky.vue';
import CDialog from '@/components/cDialog.vue';
import website from '@/config/website';
import bindEvents from './components/video-events';
import Diagnosis from './diagnosis.vue';
import Patient from './patient.vue';

export default {
  components: { cCard, CSticky, CDialog, Diagnosis, Patient },
  directives: {
  },
  data() {
    return {
      businessId: this.$route.query.businessId,
      userInfo: {},
      item: {},
      // 视频
      userId: null,
      userSig: null,
      trtcCalling: null,
      remoteUserId: null,
      videoStatus: '',
      diagnosisDialog: {
        visible: false,
        title: '下诊断',
      },
      patientDialog: {
        visible: false,
        title: '下诊断',
      },
      isLittleWindow: false,
    };
  },
  created() {
    this.getWorkDetail();
    this.getUserInfo();
    // 视频
    this.getSign();
    // this.path = `pages/videoCall/videoCall?businessId=${this.businessId}`;
    // this.getWxJssdkConf();
  },
  beforeDestroy() {
    this.trtcCalling.logout();
  },
  methods: {
    // 发起视频
    startVideo() {
      this.trtcCalling.call({
        userID: this.userInfo.userId,
        type: 2, // 1 语音 2 视频
        timeout: 1,
      });
      this.videoStatus = 'connected';
      this.$toast.loading({
        message: '发起视频中...',
        // duration: 15 * 1000,
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
        color: '#1989fa',
        overlay: true,
        getContainer: '.video-window',
      });
    },
    endVideo() {
      this.hangup();
    },
    // 缩小窗口
    shrinkWindow() {
      this.trtcCalling.stopRemoteView({
        userId: this.userId,
        videoViewDomID: 'fullwindow',
      });
      this.trtcCalling.startRemoteView({
        userId: this.userId,
        videoViewDomID: 'littlewindow',
      });
      this.isLittleWindow = true;
    },
    // 放大窗口
    enlargeWindow() {
      this.trtcCalling.startRemoteView({
        userId: this.userId,
        videoViewDomID: 'fullwindow',
      });
      this.trtcCalling.stopRemoteView({
        userId: this.userId,
        videoViewDomID: 'littlewindow',
      });
      this.isLittleWindow = false;
    },
    // 断开连接
    hangup() {
      // this.$toast.clear();
      this.trtcCalling.hangup();
      this.trtcCalling.closeCamera();
      this.videoStatus = 'idle';
    },
    // 获取用户签名
    getSign() {
      this.$get('/api/blade-consult/tencentIm/getTrtcUserSig/PC')
        .then(({ data }) => {
          this.userId = data.userId;
          this.userSig = data.userSig;
          this.login();
        });
    },
    // 登录
    login() {
      const options = {
        SDKAppID: website.videoSDKAppID,
      };

      this.trtcCalling = new TrtcCalling(options);

      this.trtcCalling.login({
        userID: this.userId,
        userSig: this.userSig,
      });
      bindEvents(this, TrtcCalling);
    },
    // 结束问诊
    endAction() {
      this.$dialog.confirm({ message: '确认结束问诊吗' }).then(() => {
        this.$get(`/api/blade-consult/doctorWorkVideo/finishAcceptsPatient/${this.businessId}`)
          .then(() => {
            this.$router.back();
          });
      });
    },
    // 过号
    passAction() {
      this.$dialog.confirm({ message: '确认过号吗' }).then(() => {
        this.$get(`/api/blade-consult/doctorWorkVideo/overNumber/${this.businessId}`)
          .then(() => {
            this.$router.back();
          });
      });
    },
    // 退款
    refundAction() {
      this.$dialog.confirm({ message: '确认结束问诊并退款吗' }).then(() => {
        this.$get(`/api/blade-consult/doctorWorkVideo/finishAcceptsPatientAndRefund/${this.businessId}`)
          .then(() => {
            this.$router.back();
          });
      });
    },
    // 病历
    toPatientPage() {
      // this.$router.push({
      //   path: '/yisheng/patient',
      //   query: {
      //     businessId: this.businessId,
      //     currentTab: '2',
      //   },
      // });
      this.patientDialog = {
        visible: true,
        title: '查看病历',
        showConfirmButton: false,
        showCancelButton: false,
        // className: 'full-screen',
      };
    },
    // 下诊断
    toDiagnosisPage() {
      // this.$router.push({
      //   path: '/yisheng/diagnosis',
      //   query: {
      //     businessId: this.businessId,
      //   },
      // });
      this.diagnosisDialog = {
        visible: true,
        title: '下诊断',
        showConfirmButton: false,
        showCancelButton: false,
        // className: 'full-screen',
      };
    },
    doneDiagnosis() {
      this.getUserInfo();
      this.closeDiagnosisDialog();
    },
    closeDiagnosisDialog() {
      this.diagnosisDialog = {
        visible: false,
      };
    },
    closePatientDialog() {
      this.patientDialog = {
        visible: false,
      };
    },
    // 获取用户的基本信息
    getUserInfo() {
      this.$get(`/api/blade-consult/doctorWork/acceptsPatient/${this.businessId}`)
        .then(({ data }) => {
          this.userInfo = data || {};
        });
    },
    getWorkDetail() {
      this.$post(`/api/blade-consult/doctorWork/getIllnessInfoResult/${this.businessId}`)
        .then(({ data }) => {
          this.item = data || {};
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  min-height: 100vh;
  .fixed-btns {
    position: fixed;
    right: 12px;
    bottom: 180px;
    text-align: center;
    .w80 {
      width: 80px;
    }
  }
  .full-window,
  .little-window {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
  }
  .little-window {
    top: 50%;
    transform: translateY(-50%);
    width: 90px;
    height: 90px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    border-radius: 0 12px 12px 0;
    &-inner {
      border-radius: 12px;
    }
  }
}
.full-screen {
  width: 100vw;
  height: 100vh;
  .close-icon {
    position: absolute;
    right: 12px;
    top: 24px;
    color: #999;
    font-size: 18px;
  }
  /deep/ .dialog {
    width: 100%;
  }
}
</style>
