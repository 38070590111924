var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"patient"},[_c('radio-button',{staticClass:"page-top",attrs:{"type":_vm.currentTab,"btnList":[
      { label: '基础信息', value: '1' },
      { label: '电子病历', value: '2' },
    ]},on:{"update:type":function($event){_vm.currentTab=$event},"change":_vm.tabChange}}),(_vm.currentTab === '1')?[_c('c-detail',{attrs:{"data":_vm.detailData,"columns":[
        { label: '患者姓名', prop: 'cardName' },
        { label: '患者卡号', prop: 'cardId' },
        { label: '患者年龄', prop: 'age' },
        { label: '出生日期', prop: 'birthday' },
        { label: '性别', prop: 'sexName' },
        { label: '手机号', prop: 'phone' },
        { label: '证件号', prop: 'cardNo' },
        { label: '接诊类型', prop: 'visitType', value: _vm.detailData.visitType === '1' ? '初诊' : '复诊' },
        { label: '婚姻状况', prop: 'marriage', type: 'select', list: [{ text: '未知', value: '' }, { text: '未婚', value: '1' }, { text: '已婚', value: '2' }] },
        { label: '学历', prop: 'education' },
        { label: '职业', prop: 'profession' },
        { label: '地址', prop: 'addressDetail' },
        { label: '备注', prop: 'remark' },
      ]}})]:_vm._l((_vm.list),function(item){return _c('c-detail',{key:item.id,attrs:{"data":item,"title":item.updateTime,"columns":[
        { label: '门诊编号', prop: 'businessId' },
        { label: '接诊医生', prop: 'doctorName' },
        { label: '科室', prop: 'deptName' },
        { label: '诊断', prop: 'diagnosis' },
      ]},nativeOn:{"click":function($event){return _vm.showDetail(item)}}})}),_c('c-dialog',{attrs:{"dialog":_vm.dialog}},[_c('div',{staticClass:"tac"},[_c('van-tag',{attrs:{"type":"primary","plain":""}},[_vm._v(_vm._s(_vm.dialog.data.visitType === '1' ? '初诊' : '复诊'))])],1),_c('div',{staticClass:"common-title"},[_vm._v("体格")]),_c('c-detail',{attrs:{"data":_vm.dialog.data,"columns":[
        { label: '体温', prop: 'temperature', className: 'w50' },
        { label: '呼吸', prop: 'breathing', className: 'w50' },
        { label: '脉搏', prop: 'pulse', className: 'w50' },
        { label: '血压', prop: 'bloodLow', value: `${_vm.dialog.data.bloodLow}~${_vm.dialog.data.bloodHigh}`, className: 'w50' },
        { label: '血糖', prop: 'bloodSugar', className: 'w50' },
        { label: '血脂', prop: 'bloodFat', className: 'w50' },
        { label: '身高', prop: 'height', className: 'w50' },
        { label: '体重', prop: 'weight', className: 'w50' },
      ]}}),_c('div',{staticClass:"common-title"},[_vm._v("病历信息")]),_c('c-detail',{attrs:{"data":_vm.dialog.data,"columns":[
        { label: '发病日期', prop: 'morbidity' },
        { label: '主诉', prop: 'mainSuit' },
        { label: '现病史', prop: 'nowMedicalHistory' },
        { label: '既往史', prop: 'pastMedicalHistory' },
        { label: '过敏史', prop: 'allergicHistory' },
        { label: '个人史', prop: 'personalHistory' },
        { label: '家族史', prop: 'familyHistory' },
        { label: '辅助检查', prop: 'auxiliaryCheck' },
        { label: '医嘱', prop: 'doctorAdvice' },
        { label: '备注', prop: 'remark' },
      ]}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }