const bindEvents = (that, TrtcCalling) => {
  // 用户接入
  that.trtcCalling.on(
    TrtcCalling.EVENT.USER_ENTER,
    async (data) => {
      console.log('视频事件', '用户进入: ', data);
      that.$toast.clear();
      const { userID } = data;
      that.remoteUserId = userID;
      await that.$get(`/api/blade-consult/doctorWorkVideo/initiateVideo/${that.businessId}`);
      that.videoStatus = 'connected';
      that.isLittleWindow = false;
      // 建立连接
      that.$nextTick(() => {
        // 需要先等远程用户 id 的节点渲染到 dom 上
        // 展示远端的视频画面
        that.trtcCalling.startRemoteView({
          userID, // 远端用户 ID
          videoViewDomID: 'fullwindow', // 该用户数据将渲染到该 DOM ID 节点里
        });
      });
    },
  );
  // sdk准备结束
  that.trtcCalling.on(
    TrtcCalling.EVENT.SDK_READY,
    (data) => {
      console.log('视频事件', 'sdk准备结束: ', data);
      that.videoStatus = 'idle';
    },
  );
  // 用户主动离开
  that.trtcCalling.on(
    TrtcCalling.EVENT.USER_LEAVE,
    (data) => {
      console.log('视频事件', '用户离开: ', data);
      that.hangup();
    },
  );
  // 用户拒绝通话
  that.trtcCalling.on(
    TrtcCalling.EVENT.REJECT,
    (data) => {
      console.log('视频事件', '用户拒绝通话: ', data);
      that.$toast('用户拒接');
      that.hangup();
    },
  );
  // 用户无应答
  that.trtcCalling.on(
    TrtcCalling.EVENT.NO_RESP,
    (data) => {
      console.log('视频事件', '用户无应答: ', data);
      that.$toast('用户无应答');
      that.hangup();
    },
  );
  // 本次通话超时未应答
  that.trtcCalling.on(
    TrtcCalling.EVENT.CALLING_TIMEOUT,
    (data) => {
      console.log('视频事件', '本次通话超时未应答: ', data);
      that.$toast('用户无应答');
      that.hangup();
    },
  );
  // 错误码
  that.trtcCalling.on(
    TrtcCalling.EVENT.ERROR,
    (data) => {
      console.log('视频事件', '错误码: ', data);
    },
  );
};

export default bindEvents;
