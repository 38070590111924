<template>
  <c-card :class="className">
    <slot>
      <div v-if="title" class="fw700 fs16 mb8">{{ title }}</div>
    </slot>
    <div v-for="(col, index) in columns" :key="col.prop" :class="[col.className || '', columns.length === index + 1 ? '' : 'mb8']">
      <slot :name="col.prop">
        <span class="c-card-label">{{ col.label }}: </span>
        <span v-if="col.type === 'select'" class="c-card-value">{{ getSelectValue(col) }}</span>
        <span v-else-if="'value' in col" class="c-card-value">{{ col.value }}</span>
        <span v-else class="c-card-value">{{ data[col.prop] || col.defaultValue }}</span>
      </slot>
    </div>
  </c-card>
</template>

<script>
import cCard from './cCard.vue';

export default {
  components: { cCard },
  name: 'CDetail',
  props: {
    data: Object,
    columns: Array,
    title: String,
    className: {
      type: String,
      default: 'label_80',
    },
  },
  methods: {
    getSelectValue(col) {
      return col.list.find(opt => opt[col.valueKey || 'value'] === this.data[col.prop])?.text;
    },
  },
};
</script>
